import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Masonry from "../../components/masonry"
import { graphql } from "gatsby"

export default ({ data }) => (
  <Layout>
    <SEO title="Schools" />

    <section className="hero is-medium is-white">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-spaced is-size-3">Schools</h1>
          <h3 className="is-size-5">
            John and Pam have been photographing schools and sporting teams for
            over 30 years. They have an impeccable eye for detail and ensure the
            end product satisfies the most discerning customers. Being a locally
            based NQ team any problems raised by parents or clients are quickly
            solved by the team at Tableland Photography.
          </h3>
        </div>
      </div>
    </section>
    <section>
      <Masonry
        items={data.schools.edges.map(({ node }) => ({
          image: { ...node.childImageSharp.fluid },
          publicURL: node.publicURL,
          name: node.name,
        }))}
        itemsPerRow={[1, 5]}
      />
    </section>
  </Layout>
)

export const data = graphql`
  query {
    schools: allFile(
      filter: { relativeDirectory: { eq: "gallery/schools" } }
    ) {
      edges {
        node {
          id
          publicURL
          name
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
